html,body{
    height: 100%;
}
#root {
    height: 100%;
}
.main {
    display: flex;
    height: calc(100% - 70px);
    & .sidebar {
        width: 15%;
        position: fixed;
        height: 100%;
        border-right: 1px solid rgba(0,0,0,.12);
        & ul {
            padding-left: 0px;
            list-style: none;
            & a {
                text-decoration: none;
                & li {
                    padding: 10px 20px;
                    color: #333;
                    &:hover {
                        background-color: rgba(0, 0, 0, 0.04);
                    }
                }
            }

        }
    }
    & .main-content {
        padding: 50px 30px 30px 30px;
        width: calc(85% - 60px);
        margin-left: 15%;
        & .fanpage-item {
            border: 1px solid #cccc;
            border-radius: 50px;
            height: 70px;
            display: flex;
            line-height: 70px;
            margin-bottom: 20px;
            & .fanpage-image {
                text-align: center;
                width: 10%;
                display: flex;
                align-items: center;
                justify-content: center;
                & img {
                    width: 50px;
                    height: 50px;
                    border-radius: 100%;
                }
            }
            & .fanpage-name {
                font-weight: bold;
                width: 80%;
            }
            & .fanpage-like {
                width: 20%;
            }
        }
        & .post-item {
            border: 1px solid #ccc;
            padding: 20px;
            border-radius: 4px;
            margin-bottom: 20px;
        }
    }
}
.header {
    background: #1B74E4;
    display: flex;
    height: 70px;
    align-items: center;
    position: sticky;
    top: 0px;
    z-index: 5;
    & .menu {
        display: flex;
        justify-content: space-between;
        align-items: center;
        & .button-active {
            background: #fff;
            color: rgba(0, 0, 0, 0.6);
        }
        & .menu-item {
            & a {
                margin-right: 15px;
            }
            & button {
                padding-left: 10px;
                padding-right: 10px;
            }
            & a.active {
                & button {
                    background: #fff;
                    color: rgba(0, 0, 0, 0.6);
                }
            }
        }
    }
    & .avatar {
        & button {
            color: #fff;
        }
    }
    & a {
        text-decoration: none;
        & button {
            color: #fff;
        }
    }
    & .left {
        width: 50%;
    }
    & .right {
        width: calc(50% - 60px);
        display: flex;
        padding: 0px 30px;
        justify-content: flex-end;
        align-items: center;
        & .user-image {
            width: 50px;
            height: 50px;
            border-radius: 100%;
        }
        & .full-name {
            padding: 0px 20px;
            font-weight: bold;
        }
    }
}
.m-t-0 {
    margin-top: 0px;
}
.mb-2 {
    margin-bottom: 2px;
}
.m-t-20 {
    margin-top: 20px;
}
.m-t-40 {
    margin-top: 40px;
}
.m-b-0 {
    margin-bottom: 0px;
}
.m-b-20 {
    margin-bottom: 20px;
}
.m-10 {
    margin: 10px;
}
.modal {
    position: fixed;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.3);
    top: 0px;
    left: 0px;
    & .modal-dialog {
        margin: 30px auto;
        background: #fff;
        max-width: 600px;
        border-radius: 4px;
        padding: 30px;
        & .close {
            cursor: pointer;
        }
    }
}
.form-control {
    width: calc(100% - 20px);
    border: 1px solid #ccc;
    outline: none;
    border-radius: 4px;
    padding: 10px;
}
.t-right {
    text-align: right;
}
.t-center {
    text-align: center;
}
.h-100 {
    height: 100%;
}
.list-comment {
    & button {
        float: right;
    }
}
.list-object-chatbot {
    list-style-type: none;
    padding-left: 0px;
    & li {
        display: inline-block;
        width: calc(20% - 12px);
        border-radius: 4px;
        border: 1px solid #ddd;
        text-align: center;
        margin-bottom: 10px;
        padding: 10px 0px;
        background: #fafafa;
        margin-right: 10px;
        cursor: pointer;
    }
}
.list-block {
    padding-left: 0px;
    list-style-type: none;
    margin: 0px;
    & li {
        background: #fafafa;
        padding: 10px 10px;
        margin-bottom: 10px;
        border: 1px solid #f0f0f0;
        border-radius: 5px;
    }
}
.list-button {
    list-style-type: none;
    padding-left: 0px;
    & li {
        padding: 5px;
        font-size: 13px;
        background: #fafafa;
        text-align: center;
        margin-bottom: 10px;
        border: 1px solid #ddd;
        border-radius: 6px;
        color: #000;
    }
}
.block {
    & .message-item {
        background: #fafafa;
        border: 1px solid #ddd;
        padding: 20px;
        margin-bottom: 20px;
        border-radius: 6px;
        vertical-align: top;
        color: #000;
    }
    // & .list-button {
    //     list-style-type: none;
    //     padding-left: 0px;
    //     & li {
    //         padding: 5px;
    //         font-size: 13px;
    //         background: #fafafa;
    //         text-align: center;
    //         margin-bottom: 10px;
    //         border: 1px solid #ddd;
    //         border-radius: 6px;
    //     }
    // }
}
.block-chat {
    display: flex;
    & .conversation {
        overflow: auto;
        width: 25%;
        max-height: 100%;
        & .customer {
            align-items: center;
            display: flex;
            border-bottom: 1px solid #ccc;
            padding: 10px 0px;
            cursor: pointer;
            & .avatar {
                width: 20%;
                & img {
                    width: 100%;
                    border-radius: 100%;
                }
            }
            & .info {
                padding-left: 20px;
            }
        }
    }
    & .message {
        padding: 20px;
        width: 50%;
        background: #e5ded8;
        & .detail-mess {
            height: 70%;
            overflow: auto;
            padding-top: 20px;
        }
        & .write-mess {
            height: 30%;
            & .order-tag {
                margin-right: 10px;
                padding: 5px;
                text-align: center;
                cursor: pointer;
                color: #fff;
                border-radius: 4px;
                font-size: 13px;
                opacity: 0.5;
                width: 10%;
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
            }

            & .done {
                background: rgb(0, 104, 56);
                opacity: 0.4;
            }
            & .cancel {
                background: orange;
                opacity: 0.4;
            }
            & .asking {
                background: rgb(46, 49, 145);
                opacity: 0.4;
            }
            & .created {
                background: #f9ec31;
                opacity: 0.4;
            }
            & .stock {
                background: red;
                opacity: 0.4;
            }
            & .order-tag-active {
                opacity: 1;
            }
            & textarea {
                height: calc(100% - 40px);
                width: calc(100% - 20px);
                outline: none;
                border: 1px solid #ccc;
                border-radius: 4px;
                padding: 0px 10px;
            }
        }
        & .mess-left {
            text-align: left;
            & .mess-text {
                
                max-width: 65%;
                float: left;
                background: #fff;
                padding: 10px 18px;
                border-radius: 20px;
                
            }
        }
        & .clear {
            margin: 10px 0px;
            &::after {
                display: table;
                content: " ";
                clear: both;
            }
        }
        & .mess-right {
            text-align: right;
            & .mess-text {
                max-width: 65%;
                float: right;
                text-align: left;
                background: #0084ff;
                color: #fff;
                padding: 10px 18px;
                border-radius: 20px;
                & a {
                    color: #fff;
                    -webkit-line-clamp: 1;
                    -webkit-box-orient: vertical;
                    display: -webkit-box;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
                
            }
        }
        & .image, .video {
            & .mess-text {
                background: unset;
            }
        }
    }
    & .order {
        width: 25%;
    }
}
.list-filed-webhook {
    display: flex;
    flex-wrap: wrap;
    & label {
        width: 25%;
    }
}
.const {
    background: rgb(145, 10, 154);
    color: rgb(255, 255, 255);
}
.fanpage-item {
    text-align: center;
    padding: 20px;
    height: 240px;
    border: 1px solid #eaeaea;
    border-radius: 8px;
    & .avatar-fanpage {
        width: 50px;
        height: 50px;
        border-radius: 100%;
        border: 2px solid #ccc;
        padding: 2px;
    }
    & .name-page {
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        display: -webkit-box;
        overflow: hidden;
        text-overflow: ellipsis;
        font-weight: 500;
    }
    & a {
        text-decoration: none;
    }
    & .icon {
        & svg {
            margin: 0px 5px;
            color: #a8a8a8;
            cursor: pointer;
        }
    }
    & .status {
        font-size: 14px;
    }
    & .status-active {
        color: green;
    }
    & .status-in-active {
        color: red;
    }
}
.add-fanpage {
    display: flex;
    background: #208ef0;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    & img {
        width: 100px;
        cursor: pointer;
    }
    & p {
        width: 100%;
        margin: 0px;
        color: #fff;
        font-size: 18px;
    }
}
.reload {
    text-decoration: none;
    color: #fff;
}
.messenger-box {
    display: flex;
    width: 100%;
    height: calc(100% - 70px);
    position: fixed;
    & .list-user {
        display: flex;
        width: 25%;
        & .conversation {
            // width: 100%;
            height: 92%;
            overflow: hidden;
            overflow-x: hidden;
            overflow-y: visible;
        }
        & .load-more {
            padding: 10px 0px;
        }
        & .filter {
            background: #102233;
            width: 10%;
            & .active {
                background: #29405f;
                color: #fff;
            }
            & > ul {
                margin: 0px;
                padding-left: 0px;
                & > li {
                    position: relative;
                    color: #97a5ac;
                    text-align: center;
                    padding: 10px 0px;
                    cursor: pointer;
                    & svg {
                        font-size: 19px;
                    }
                    & .list-saler {
                        background: #fff;
                        position: absolute;
                        width: 200px;
                        left: 38px;
                        top: 0px;
                        max-height: 300px;
                        overflow: auto;
                        z-index: 333;
                        display: none;
                    }
                    & .date-search {
                        background: #fff;
                        padding: 20px 20px;
                    }
                    &:hover {
                        background: #29405f;
                    }
                    &:hover .list-saler {
                        display: block;
                    }
                    & svg:hover .list-saler {
                        display: block;
                    }
                }
            }
        }
        & .customer-active {
            background: rgba(45, 136, 255, 0.1);
        }
        & .info {
            & .name {
                font-size: 15px;
                // margin-bottom: 10px;
                color: #000;
            }
            & p svg {
                font-size: 15px;
            }
        }
        & .customer {
            border-bottom: 1px solid #eee;
            padding: 8px 10px;
            cursor: pointer;
            display: flex;
            width: 100%;
            & .avatar {
                position: relative;
                & .num-mess {
                    position: absolute;
                    bottom: 115px; 
                    left: 35px;
                    & span {
                        font-size: 10px;
                        height: 18px;
                        background: #f5222d;
                        width: 18px;
                        min-width: 18px;
                        border-radius: 100%;
                    }
                    
                }
                & img {
                    // width: 70px;
                    // height: 70px;
                    // border-radius: 100%;
                    // margin-right: 15px;
                    // object-fit: cover;
                    // margin-right: 10px;
                }
            }
            & .info {
                width: calc(100% - 85px);
                margin-left: 15px;
            }
            & p {
                font-size: 12px;
                color: #909090;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
                display: -webkit-box;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }
        & .unread {
            font-weight: bold;
        }
        & .search {
            z-index: 1;
            display: flex;
            background: #F0F2F5;
            padding: 5px 20px;
            height: calc(8% - 10px);
            border-bottom: 1px solid #e2e2e2;
            // position: sticky;
            // top: 0px;
            align-items: center;
            & input {
                outline: none;
                width: calc(100% - 20px);
                border: 1px solid #ddd;
                height: 35px;
                border-radius: 4px;
                padding: 0px 10px;
            }
            & select {
                height: 45px;
                border: 1px solid #ddd;
                outline: none;
                border-radius: 4px;
                padding: 0px 10px;
            }
        }
    }
    & .list-user .conversation, .detail-mess {
        /* width */
        &::-webkit-scrollbar {
            width: 7px;
        }
        
        /* Track */
        // &::-webkit-scrollbar-track {
        //     background: #f1f1f1; 
        // }
        
        /* Handle */
        &::-webkit-scrollbar-thumb {
            background: #e4e6eb; 
            border-radius: 4px;
            // display: none;
        }
        // &:hover::-webkit-scrollbar-thumb {
        //     display: block;
        // }
        
        /* Handle on hover */
        &::-webkit-scrollbar-thumb:hover {
            background: #ccc; 
        }
    }
    & .book {
        & .title-bill {
            padding: 5px 20px;
            height: 8%;
            background: #F0F2F5;
            display: flex;
            border-bottom: 1px solid #e2e2e2;
            align-items: center;
            & span {
                font-weight: 500;
                font-size: 18px;
            }
        }
        & .form-book {
            // height: calc(92% - 40px);
            // padding: 20px 20px;
            // overflow: hidden;
            // overflow-x: hidden;
            // overflow-y: visible;
            height: calc(92% - 30px);
            padding: 15px !important;
            overflow: hidden;
            overflow-x: hidden;
            overflow-y: visible;
            & .customer-info {
                width: 100%;
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
            }
            & .table-cart {
                & tr th {
                    padding: 10px 16px !important;
                }
                & tr td {
                    padding: 10px 16px !important;
                }
            }
            &::-webkit-scrollbar {
                width: 7px;
            }
            &::-webkit-scrollbar-thumb {
                background: #e4e6eb; 
                border-radius: 4px;
                // display: none;
            }
            &::-webkit-scrollbar-thumb:hover {
                background: #ccc; 
            }
            & .input-item {
                width: 48%;
                margin-bottom: 20px;
                // & input, select{
                //     height: 40px;
                //     border-radius: 3px;
                //     // border: 1px solid #ccc;
                //     outline: none;
                //     padding: 0px 10px;
                // }
                & input {
                    width: calc(100% - 20px);
                }
                & select {
                    width: 100%;
                }
            }
            & .input-textarea {
                width: 100%;
                & textarea {
                    width: calc(100% - 30px);
                    padding: 15px;
                    outline: none;
                    border: 1px solid rgba(0, 0, 0, 0.23);
                    border-radius: 4px;
                }
            }
        }
    }
    & .chat {
        width: 50%;
        border-left: 1px solid #ebeaea;
        border-right: 1px solid #ebeaea;
        & .chat-empty {
            text-align: center;
            font-size: 25px;
            color: #666;
            margin-top: 100px;
        }
        & .info-user-chat{
            background: #F0F2F5;
            padding: 5px 20px;
            height: 8%;
            display: flex;
            border-bottom: 1px solid #e2e2e2;
            align-items: center;
            & .info {
                width: 60%;
                display: flex;
                align-items: center;
                & .avatar {
                    width: 35px;
                    height: 35px;
                    & img {
                        border-radius: 100%;
                        width: 40px;
                        height: 40px;
                        object-fit: cover;
                    }
                }
                & .full-name {
                    padding-left: 10px;
                    width: calc(100% - 50px);
                }
            }
            & .setting {
                width: 40%;
                display: flex;
                align-items: center;
                text-align: right;
                justify-content: right;
                & svg {
                    padding: 0px 10px;
                    cursor: pointer;
                }
            }
        }
        & .detail-mess {
            height: 62%;
            padding-top: 20px;
            // padding-bottom: 20px;
            overflow: hidden;
            overflow-x: hidden;
            overflow-y: visible;
            border-bottom: 1px solid #f0f0f0;
        }
        & .write-mess {
            background: #f7f7f7;
            height: 30%;
            position: relative;
            & .tag {
                display: flex;
                align-items: center;
                height: 30%;
                padding: 0px 10px;
                & .order-tag {
                    margin-right: 10px;
                    padding: 5px 10px;
                    text-align: center;
                    cursor: pointer;
                    color: #fff;
                    border-radius: 4px;
                    font-size: 12px;
                    opacity: 0.4;
                    width: 10%;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    white-space: nowrap;
                }
    
                & .done {
                    background: rgb(0, 104, 56);
                    opacity: 0.4;
                }
                & .cancel {
                    background: orange;
                    opacity: 0.4;
                }
                & .asking {
                    background: rgb(46, 49, 145);
                    opacity: 0.4;
                }
                & .created {
                    background: #f9ec31;
                    opacity: 0.4;
                }
                & .stock {
                    background: red;
                    opacity: 0.4;
                }
                & .order-tag-active {
                    opacity: 1;
                }
            }
            & .form-chat {
                display: flex;
                padding: 0px 10px;
                margin-top: 10px;
                height: calc(50% - 30px);;
                & .avatar {
                    width: 30px;
                    height: 30px;
                }
                & form {
                    width: calc(100% - 30px);
                    padding: 0px 10px;
                    & textarea {
                        border: 0px;
                        height: 100%;
                        width: calc(100% - 20px);
                        outline: none;
                        // border: 1px solid #f0f0f0;
                        border-radius: 4px;
                        padding: 0px 10px;
                        font-size: 20px;
                        background: #f7f7f7;
                        resize: none;
                    }
                }
            }
            & .attachment {
                height: 20%;
                text-align: right;
                padding: 0px 10px;
                & svg {
                    padding: 0px 8px;
                    color: rgba(0,0,0,.36);
                    cursor: pointer;
                }
                & .file {
                    display: none;
                }
                &>div {
                    display: inline-block;
                }
            }
        }
        & .mess-left {
            padding: 0px 20px;
            text-align: left;
            display: flex;
            & .avatar {
                width: 50px;
                height: 50px;
                margin-right: 10px;
                & img {
                    width: 50px;
                    height: 50px;
                    border-radius: 100%;
                    object-fit: cover;
                }
            }
            & .mess-text {
                max-width: 65%;
                float: left;
                background: #e4e6eb;
                padding: 10px 18px;
                border-radius: 10px;
                font-size: 18px;
                word-break: break-word;
                & img {
                    border-radius: 0.3em;
                    border: 1px solid #f0f0f0;
                    width: 30%;
                    float: left;
                    height: 100px;
                    object-fit: cover;
                    margin: 0px 5px 10px 0px;
                }
            }
        }
        & .clear {
            margin: 10px 0px;
            &::after {
                display: table;
                content: " ";
                clear: both;
            }
        }
        & .mess-right {
            padding: 0px 20px;
            text-align: right;
            display: flex;
            justify-content: flex-end;
            & .avatar {
                width: 50px;
                height: 50px;
                margin-left: 10px;
                & img {
                    width: 100%;
                    border-radius: 100%;
                }
            }
            & .mess-text {
                word-break: break-word;
                font-size: 18px;
                position: relative;
                max-width: 65%;
                float: right;
                text-align: left;
                background: #0084ff;
                color: #fff;
                padding: 10px 18px;
                border-radius: 10px;
                & video {
                    float: right;
                }
                & img {
                    border-radius: 0.3em;
                    border: 1px solid #f0f0f0;
                    width: 30%;
                    height: 100px;
                    object-fit: cover;
                    margin: 0px 5px 10px 0px;
                    float: right;
                }
                & a {
                    color: #fff;
                    -webkit-line-clamp: 1;
                    -webkit-box-orient: vertical;
                    display: -webkit-box;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
                & .check-send {
                    position: absolute;
                    color: gray;
                    right: -15px;
                    font-size: 12px;
                    bottom: 2px;
                }
                & .check-send-attachment {
                    bottom: 15px;
                }
            }
        }
        & .image, .video {
            & .mess-text {
                background: unset;
            }
        }
    }
    & .book {
        width: 25%;
    }
}
.selection-box {
    position: absolute;
    border: 1px dashed #000; /* Màu và kiểu viền lưới */
    background-color: rgba(0, 0, 0, 0.1); /* Màu nền của lưới */
    pointer-events: none; /* Ngăn lưới ảnh hưởng đến sự kiện chuột */
}
.swal2-container {
    z-index: 106000 !important;
    & p {
    margin: 0px;
    font-size: 20px;
    }
}
.block-image {
    & .name {
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        display: -webkit-box;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    & .lib-image {
        width: 100%;
        height: 200px;
        object-fit: cover;
    }
}
.textarea {
    width: calc(100% - 20px);
    outline: none;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
}
.icon {
    color: rgba(0, 0, 0, 0.36);
}
.w-100 {
    width: 100%;
}
.cursor {
    cursor: pointer;
}
.text-transform{
    text-transform: unset !important;
}
.nowrap {
    white-space: normal;
}
.label {
    padding: 0.3rem 0.3rem;
    border-radius: 0.3rem;
    text-align: center;
  }
.label-primary{
background: #3699FF;
color: #fff;
}
.label-warning {
    background: #FFA800;
    color: #fff;
}
.label-info {
background: #8950FC;
color: #fff;
}
.label-danger {
color: #fff;
background-color: #F64E60;
}
.label-success {
color: #fff;
background-color: #1BC5BD;
}
.highlight-phone-number {
    font-weight: 400;
    color: #fff;
    border-radius: 4px;
    padding: 2px 5px;
    text-align: center;
    background: #ff8100;
}
.cursor-copy {
    cursor: copy;
    
}
.alert-copy {
    top: 100px;
    right: 0;
    position: absolute;
    left: 0;
    width: 180px;
    margin-left: auto !important;
    margin-right: auto !important;
    z-index: 2;
}
.action-comment {
    margin-top: 5px;
    & svg {
        font-size: 18px;
        cursor: pointer;
        margin-right: 5px;
    }
}
.hidden-active {
    color: #1B74E4;
}
.list-page-connect {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    & img {
        height: 50px;
        width: 50px;
        border-radius: 100%;
        border: 1px solid #ccc;
    }
    & div:nth-child(3) {
        padding: 0px 10px;
        text-align: left;
        width: 65%;
    }
    // & div:nth-child(1) {
    //     width: 15%;
    // }
    & div:nth-child(4) {
        width: 20%;
        text-align: right;
    }
}
.a-none {
    text-decoration: none !important;
    color: unset;
}
.f-20 {
    font-size: 20px;
}
b {
    font-weight: 500 !important;
}
.display-flex {
    display: flex !important;
}
.align-item {
    align-items: center;
}
.justify-content-between {
    justify-content: space-between;
}
.limit-one-row {
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
}
.justify-content-center {
    justify-content: center;
}
.space-between {
    justify-content: space-between;
}
.css-fb-custom {
    background: #1877F2 !important;
    display: -webkit-inline-box;
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    position: relative;
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
    outline: 0;
    border: 0;
    margin: 0;
    border-radius: 0;
    padding: 0;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    vertical-align: middle;
    -moz-appearance: none;
    -webkit-appearance: none;
    -webkit-text-decoration: none;
    text-decoration: none;
    color: inherit;
    font-family: "Roboto","Helvetica","Arial",sans-serif;
    font-weight: 500;
    font-size: 0.9375rem;
    line-height: 1.75;
    letter-spacing: 0.02857em;
    text-transform: uppercase;
    min-width: 64px;
    padding: 8px 22px;
    border-radius: 4px;
    -webkit-transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    color: #fff;
    background-color: #2e7d32;
    width: 100%;
    -webkit-transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}
.align-items-center {
    align-items: center;
}
.more-tag .MuiButton-startIcon {
    margin: 0px;
}
.status-order tr td {
    padding: 5px 3px;
}
.m-r-5 {
    margin-right: 5px !important;
}
#alert-dialog-description img {
    max-width: 100%;
}
.position-relative {
    position: relative;
}
.image-attachment svg{
    position: absolute;
    right: 0px;
    background: #000;
    color: #fff;
    font-size: 18px;
}
.result-search-mess {
    position: absolute;
    left: 0px;
    top: -34px;
    background: #1c6ae1;
    color: #fff;
    // width: calc(100% - 20px);
    padding: 5px 10px !important;
}
.crosshair {
    cursor: crosshair;
}
.option-fanpage {
    color: #fff;
    border-radius: 4px;
    padding: 2px;
    margin-right: 5px;
    font-size: 20px !important;
}
.option-fanpage-pause-message {
    background: rgb(237, 108, 2);
}
.option-fanpage-remove {
    background: rgb(211, 47, 47);
}
.option-fanpage-chatbot {
    background: #208ef0;
}
.option-fanpage-post {
    background: green;
}
.post-content {
    -webkit-line-clamp: 6;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    font-size: 16px;
    line-height: 20px;
    overflow: hidden;
    text-overflow: ellipsis;
}
.block-group-item {
    padding: 16px 16px 16px 16px;
    background: #fafafa;
    margin-bottom: 20px;
    border: 1px solid #f0f0f0;
    border-radius: 5px;
}
.line-1 {
    margin: 0px;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
}
.message-reply {
    padding: 10px;
    background: #d3d3d3;
    max-width: 200px;
    opacity: 0.5;
    border-radius: 5px;
}