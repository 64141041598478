body {
    font-size: 16px;
}
.page-login {
    text-align: center;
    background: aliceblue;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    & button {
        border-radius: 4px !important;
        background: #2979ff !important;
        border: 0px !important;
    }
    & ul {
        text-align: center;
        padding-left: 0px;
        li {
            display: inline-block;
            margin: 0px 10px;
            & a {
                text-decoration: none;
            }
        }
    }
    & .logo {
        width: 200px;
    }
}
.page-login-password {
    & table {
        width: 100%;
        & input {
            height: 40px;
            width: 100%;
            margin-bottom: 10px;
            padding-left: 10px;
            outline: none;
            border-radius: 4px;
            border: 1px solid #f0f0f0;
        }
        & button {
            color: #fff;
            height: 40px;
            width: 100%;
            cursor: pointer;
        }
    }
}
.text-danger {
    color: red;
}
.error-validate {
    margin: 0px 0px 10px 0px;
    text-align: left;
    font-size: 14px;
    color: red;
}